import { notification } from "antd"
import { LoadingOutlined } from '@ant-design/icons';

export const messagePending = (key: string, head: string, text: string) => {
    notification.open({
        key: key,
        duration: 0,
        message: head,
        description: text,
        placement: "bottomLeft",
        icon: <LoadingOutlined style={{ color: "#ffb90f"}}/>,
    })
}

export const messageSuccess = (key: string, head: string, text: string) => {
    notification.success({
        key: key,
        message: head,
        description: text,
        placement: "bottomLeft",
    })
}

export const messageInfo = (key: string, head: string, text: string) => {
    notification.info({
        key: key,
        message: head,
        description: text,
        placement: "bottomLeft",
    })
}

export const messageError = (key: string, head: string, text: string) => {
    notification.error({
        key: key,
        message: head,
        description: text,
        placement: "bottomLeft",
    })
}
