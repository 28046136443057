import { QueryClient, useMutation } from "@tanstack/react-query"
import { postNewOffer, statusOffer, archiveOffer, postNewBill, archiveBill, cancelBill, solvePaymentEscalation } from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"
import { useState } from "react"

// ADD Offer
export const useAddOfferMutation = (queryClient: QueryClient, modalCon: any, clearInput: () => void) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, any>({
        mutationFn: (variables) => postNewOffer(variables.body, variables.leistungsVerzeichnis),
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Angebot wird gerade erstellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Angebot wurde erfolgreich erstellt.")
            clearInput()
            modalCon?.trigger(0)
            queryClient.invalidateQueries({
                queryKey: ["offerData1"],
            })
        },
        onError: (err) => {
            clearInput()
            modalCon?.trigger(0)
            messageError(notificationKey, "Fehler!", "Das Angebot konnte nicht erstellt werden.")
            console.log(err)
        },
    })

    return mutation
}

export const useUpdateStatusOfferMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: { status: string } }>({
        mutationFn: statusOffer,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Status des Angebots wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Status des Angebots wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Status des Angebots konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

export const useArchiveOfferMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveOffer,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Angebot wird gerade archiviert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Das Angebot wurde erfolgreich archiviert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Angebot konnte nicht archiviert werden.")
            console.log(err)
        },
    })

    return mutation
}

// ====================================================== BILL ======================================================  //

export const useAddBillMutation = (queryClient: QueryClient, offer_id?: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, any>({
        mutationFn: postNewBill,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnung wird gerade erstellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnung wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: ["billData1"],
            })
            queryClient.invalidateQueries({
                queryKey: ["paymentsData1"],
            })
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + offer_id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnung konnte nicht erstellt werden.")
            console.log(err)
        },
    })
    return mutation
}

export const useArchiveBillMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveBill,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnung wird gerade archiviert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnung wurde erfolgreich archiviert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnung konnte nicht archiviert werden.")
            console.log(err)
        },
    })

    return mutation
}

export const useCancelBillMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            id: string
            body: { canceled: boolean }
        }
    >({
        mutationFn: cancelBill,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Rechnung wird gerade storniert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Die Rechnung wurde erfolgreich storniert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Rechnung konnte nicht storniert werden.")
            console.log(err)
        },
    })

    return mutation
}

// ====================================================== PAYMENT REQUEST ======================================================  //
export const useSolveEscalationMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, String>({
        mutationFn: solvePaymentEscalation,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Mahnungsprozess wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Mahnungsprozess wurde erfolgreich aktualisiert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Mahnungsprozess konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}
