import { useMutation } from "@tanstack/react-query"
import { sendHelpMessage } from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"
import { useState } from "react"
type HelpMessageType = {
    topic: string
    description: string
}
export const useSendHelpMutation = () => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, HelpMessageType>({
        mutationFn: sendHelpMessage,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Nachricht wird gerade zugestellt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Nachricht wurde erfolgreich zugestellt.")
        },
        onError: (err) => {
            messageError(
                notificationKey,
                "Fehler!",
                "Die Meldung konnte nicht zugestellt werden. Bitte kontaktieren Sie umgehend die IT-Dienstleister per Telefon oder Mail!"
            )
            console.log(err)
        },
    })

    return mutation
}
