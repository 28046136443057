import BaseSelection from "../../../components/elements/BaseSelection"

const timeElements = [
    "/",
    "wöchentlich",
    "14 tägig",
    "monatlich",
    "1x jährlich",
    "2x jährlich",
    "4x jährlich",
    "nach Turnus",
    "zubuchbar",
    "nach Bedarf",
    "ereignisbezogen",
    "auf Anforderung",
]
const table1Rows = ["Überprüfung des allgemeinen Zustandes im Objekt, Meldung von Schäden, Mängeln und Pflegehinweisen"]
const table2Rows = [
    "Überprüfung der mechanischen Einrichtungen wie Türschließautomaten, Zylinder, Federbänder, Schlösser, Brandschutztüren",
    "Kontrolle der Außenbeleuchtung und Austausch defekter Leuchtmittel",
    "Kontrolle des Fluchtwegbeleuchtung, Brandmeldeanlage, Wandhydranten etc.",
    "Sichtprüfung der Regenrinne und Fallrohre. Von unten Sichtbar",
    "Verschließen und Entleeren / Öffnen der Außenwasserhähne",
    "Rückspülen der Filtereinrichtungen in der Kaltwasserleitung",
]
const table3Rows = [
    "Reinigung / Unkrautentfernung der Verkehrsflächen im öffentlichen Außenbereich mit geeigneter Technik",
    "Reinigung / Unkrautentfernung der Verkehrsflächen im privaten Außenbereich mit geeigneter Technik",
    "Laubentsorgung von Hauseingängen, Wegen, Zufahrten, und Hofflächen reinigen",
    "Wassereinläufe und Gullys von Schmutz befreien",
    "Reinigung des Abfallbehälterstellplatzes",
    "Bereitstellung der Abfallbehälter zur Abholung durch den Entsorger",
]
const table4Rows = [
    "Kehren der Bodenbeläge, Schmutzaufnahme vor dem Wischgang",
    "Feuchtes Wischen der Bodenbeläge mit umweltverträglichem Reiniger",
    "Geländer und Handläufe abwischen",
    "Entfernung von Spinnweben im Treppenhaus",
    "Fensterbretter, Brüstung, Schalter feucht abwischen",
    "Briefkästen und Klingeltableau feucht abwischen",
    "Reinigung der Hauseingangstür inkl. Entfernung von Griff- und Trittspuren auch an Glasflächen",
    "Lichtblenden und Beleuchtungskörper reinigen",
    "Treppenhausfenster und Glasbausteine allseitig, soweit ohne Gefährdung erreichbar, durch Einwaschen und Abziehen reinigen, inkl. Rahmen abwaschen und abledern",
    "Kellergänge, Heizungsraum und Technische Räume kehren",
    "Reinigen der Flächen in Technikräumen / Heizraum einschl. Einbauten, Ablagen, Rohrleitungen, Fensterbänke, Ausguß, Bodeneinläufe, Spinnweben",
    "Waschhaus",
]
const table5Rows = [
    "Füllstand der Heizung kontrollieren und bei Bedarf auffüllen (Wasserdruck)",
    "Umwälz- und Ladepumpe für Kesselheizung auf Dichtigkeit und Laufruhe kontrollieren",
    "Kontrolle und Überwachung der Heizungsanlage. Bei Störung sofortige Meldung an das Energieversorgungsunternehmen.",
    "Meldung der Zählerstände im Gemeinschaftsbereich an den Auftraggeber nach Bestellung",
]
const table6Rows = [
    "Papier, Steine und sonstigen Unrat aus der Gartenanlage beseitigen",
    "Rasen mähen auf geraden Flächen inkl. Aufnehmen des Schnittgutes (in der Regel April-September)",
    "Entsorgung von Grünabfällen",
    "Hecken im Frühjahr und im Herbst schneiden",
    "Ziersträucher ( bis 3 Meter Höhe ) verschneiden",
    "Unkrautbekämpfung auf und an befestigten Verkehrsflächen",
    "Entfernung von Laub aus Beet- und Rasenflächen",
    "Bewässern der Grünpflanzen",
    "Beetpflege als Grundpflege, Unkraut entfernen, verblühte und abgestorbene Triebe entfernen",
    "Objektdurchgang zur Aufnahme notwendiger Arbeiten in der Grünfläche",
]
const table7Rows = [
    "Schneeberäumung / Abstumpfen der öffentlichen Flächen nach den derzeit gültigen Bestimmungen der Winterdienstsatzung der Stadt Dresden",
    "Schnee- und Glättebeseitigung / Abstumpfen auf Hauszuwegungen",
    "Entfernen des Streugutes im Rahmen der Außenreinigung",
]
const table8Rows = [
    "Der Hausmeisterservice ist Tag und Nacht, auch sonn- und feiertags, telefonisch erreichbar",
    "Der Notdienst ist abrufbar bei Wasserrohrbruch, Heizungsausfall, Kanalverstopfung, Stromausfall und sonstigen wirklichen Notfällen. Dem Auftraggeber wird Bericht erstattet",
    "Der Notdiensteinsatz wird vom Auftragnehmer nach Stundenaufwand gemäß Punkt 10 abgerechnet",
    "Material und Ersatzteile für die Behebung kleinerer Schäden werden dem Auftraggeber gesondert in Rechnung gestellt",
]
const table9Rows = [
    "Sonderleistungen werden nach Absprache mit dem Auftraggeber gegen Einzelbeauftragung durchgeführt",
    "Material und Ersatzteile für die Behebung kleinere Schäden werden dem Auftraggeber gesondert in Rechnung gestellt",
    "Wird die Durchführung größerer Reparaturen oder Erneuerungen erforderlich, so wird der Auftragnehmer dem Auftraggeber einen Kostenvoranschlag unterbreiten und ggf. unter Einschaltung von Fachfirmen aufgrund gesonderter Beauftragung tätig",
    "Material und Ersatzteile für die Behebung kleinerer Schäden werden dem Auftraggeber gesondert in Rechnung gestellt",
]

type LeistungsverzeichnisType = {
    data: any // TODO
    changeInput: (key: string, value: string) => void
}

export default function Leistungsverzeichnis(props: LeistungsverzeichnisType) {
    return (
        <div className="bg-white w-full flex flex-col rounded-default mt-4 p-4">
            <div className="flex flex-col gap-4 h-fit">
                <h4 className="font-semibold unterline pl-4 underline text-xl">1. Hausmeisterdienst</h4>
                {table1Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["HM" + (index + 1)] ?? undefined}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("HM" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">2. Haustechnik</h4>
                {table2Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["HT" + (index + 1)]}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("HT" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">3. Reinigungsdienst außen</h4>
                {table3Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["RA" + (index + 1)] ?? undefined}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("RA" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">4. Reinigungsdienst innen</h4>
                {table4Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["RI" + (index + 1)] ?? undefined}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("RI" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">5. Heizung</h4>
                {table5Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["HZ" + (index + 1)] ?? undefined}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("HZ" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">6. Gartenpflege</h4>
                {table6Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        placeholder={"Bitte auswählen"}
                        defaultValue={props.data["GP" + (index + 1)] ?? undefined}
                        data={timeElements}
                        setId={(e) => props.changeInput("GP" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">7. Winterdienst</h4>
                {table7Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["WD" + (index + 1)] ?? undefined}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("WD" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">8. Notdienst</h4>
                {table8Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["ND" + (index + 1)] ?? undefined}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("ND" + (index + 1), e)}
                    />
                ))}
                <h4 className="font-semibold unterline pl-4 underline text-xl pt-2">9. Sonderleistungen</h4>
                {table9Rows.map((item, index) => (
                    <BaseSelection
                        key={index}
                        label={index + 1 + ") " + item}
                        defaultValue={props.data["SL" + (index + 1)] ?? undefined}
                        placeholder={"Bitte auswählen"}
                        data={timeElements}
                        setId={(e) => props.changeInput("SL" + (index + 1), e)}
                    />
                ))}
            </div>
        </div>
    )
}
