import { motion } from "framer-motion"
import BaseInputField from "./BaseInputField"
import { useState } from "react"
import {
    defaultBillCategorie,
    defaultBillType,
    defaultOfferStatusValues,
    defaultPayreqStatusValues,
    defaultPositions,
} from "../../data/defaultValues"
import dayjs from "dayjs"

type FilterModalType = {
    removeFilter: () => void
    visible: boolean
    setVisible: () => void
    statusType: "offer" | "payreq" | "bill" | "object" | "employee" | "customer"
    searchArchived?: boolean | undefined
    changeArchived?: (e: boolean | undefined) => void
    searchStatus?: string
    changeStatus?: (c: string) => void
    searchdateSince?: string
    changeDateSince?: (e: string) => void
    searchdateTo?: string
    changeDateTo?: (e: string) => void
    searchbillType?: string
    changebillType?: (e: string) => void
    searchPosition?: string | undefined
    changePosition?: (e: string | undefined) => void
    searchbillCategorie?: string
    changebillCategorie?: (e: string) => void
}

export default function BaseFilterModal(props: FilterModalType) {
    const [billType, setbillType] = useState(props.searchbillType ?? "")
    const [billCategorie, setbillCategorie] = useState(props.searchbillType ?? "")
    const [archived, setarchived] = useState(props.searchArchived ?? undefined)
    const [status, setstatus] = useState(props.searchStatus ?? "")
    const [position, setposition] = useState(props.searchPosition ?? undefined)
    const [dateSince, setdateSince] = useState(props.searchdateSince ?? "")
    const [dateTo, setdateTo] = useState(props.searchdateTo ?? "")
    const useFilter = () => {
        if (props.changeStatus) {
            props.changeStatus(status)
        }
        if (props.changeArchived) {
            props.changeArchived(archived)
        }
        if (props.changeDateSince && dateSince !== "") {
            props.changeDateSince(dayjs(dateSince).toISOString())
        }
        if (props.changeDateTo && dateTo !== "") {
            props.changeDateTo(dayjs(dateTo)?.toISOString())
        }
        if (props.changebillType) {
            props.changebillType(billType)
        }
        if (props.changePosition) {
            props.changePosition(position)
        }
        if (props.changebillCategorie) {
            console.log(billCategorie)
            props.changebillCategorie(billCategorie)
        }
        props.setVisible()
    }

    const removeInternFilterValues = () => {
        setarchived(undefined)
        setstatus("")
        setdateSince("")
        setdateTo("")
        setbillType("")
        setbillCategorie("")
        setposition("")
    }

    return (
        <motion.div
            initial={{
                x: props.statusType !== "employee" ? "150%" : "",
                y: props.statusType === "employee" ? "-150%" : "",
                opacity: props.statusType === "employee" ? 0 : "",
            }}
            animate={{
                x: props.statusType !== "employee" ? (props.visible ? 0 : "150%") : 0,
                y: props.statusType === "employee" ? (props.visible ? 0 : "-100%") : 0,
                opacity: props.statusType === "employee" ? (props.visible ? 1 : 0) : 1,
            }}
            transition={{
                type: "ease",
                duration: 0.3,
            }}
            className={`absolute -top-16 lg:top-20 right-0 lg:right-4 pt-8 h-[32rem] sm:h-fit w-full sm:w-96 rounded-default flex flex-col shadow-lg bg-white px-4 pb-4 z-[99] overflow-y-scroll`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 absolute top-4 left-4 cursor-pointer"
                onClick={props.setVisible}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            {/* Filter Bill Categories */}
            {props.changebillCategorie && (
                <div className="flex flex-col gap-1 pt-4">
                    <h3 className="py-1 font-medium">Nach Kategorie filtern</h3>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-billCategorie"
                            className="radio"
                            value="option1"
                            checked={billCategorie === defaultBillCategorie.HAUSMEISTER}
                            onChange={() => setbillCategorie(defaultBillCategorie.HAUSMEISTER)}
                        />
                        <p>Hausmeister</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-billCategorie"
                            className="radio"
                            value="option2"
                            checked={billCategorie === defaultBillCategorie.SCHLUESSELDIENST}
                            onChange={() => setbillCategorie(defaultBillCategorie.SCHLUESSELDIENST)}
                        />
                        <p>Schlüsseldienst</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-billCategorie"
                            className="radio"
                            value="option3"
                            checked={billCategorie === defaultBillCategorie.BAU}
                            onChange={() => setbillCategorie(defaultBillCategorie.BAU)}
                        />
                        <p>Bau</p>
                    </div>
                </div>
            )}
            {/* Filter Bill Types */}
            {props.changebillType && (
                <div className="flex flex-col gap-1 pt-4">
                    <h3 className="py-1 font-medium">Nach Typ filtern</h3>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-billType"
                            className="radio"
                            value="option1"
                            checked={billType === defaultBillType.NORMAL}
                            onChange={() => setbillType(defaultBillType.NORMAL)}
                        />
                        <p>Rechnung (Normal)</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-billType"
                            className="radio"
                            value="option2"
                            checked={billType === defaultBillType.RECURING}
                            onChange={() => setbillType(defaultBillType.RECURING)}
                        />
                        <p>Dauerrechnung</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-billType"
                            className="radio"
                            value="option3"
                            checked={billType === defaultBillType.YEAR}
                            onChange={() => setbillType(defaultBillType.YEAR)}
                        />
                        <p>Jahresrechnung</p>
                    </div>
                </div>
            )}
            {/* Filter Status Offer */}
            {props.changeStatus && props.statusType === "offer" && (
                <div className="flex flex-col gap-1 pt-4">
                    <h3 className="py-1 font-medium">Nach Status filtern</h3>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-archived"
                            className="radio"
                            value="option1"
                            checked={status === defaultOfferStatusValues.DONE}
                            onChange={() => setstatus(defaultOfferStatusValues.DONE)}
                        />
                        <p>Angenommen</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-archived"
                            className="radio"
                            value="option2"
                            checked={status === defaultOfferStatusValues.OPEN}
                            onChange={() => setstatus(defaultOfferStatusValues.OPEN)}
                        />
                        <p>Ausstehend</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-archived"
                            className="radio"
                            value="option3"
                            checked={status === defaultOfferStatusValues.CANCELED}
                            onChange={() => setstatus(defaultOfferStatusValues.CANCELED)}
                        />
                        <p>Abgelehnt</p>
                    </div>
                </div>
            )}
            {/* Filter Status Payment Requirements */}
            {props.changeStatus && props.statusType === "payreq" && (
                <div className="flex flex-col gap-1 pt-4">
                    <h3 className="py-1 font-medium">Nach Status filtern</h3>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-bill-1"
                            className="radio"
                            value="option1"
                            checked={status === defaultPayreqStatusValues.DONE}
                            onChange={() => setstatus(defaultPayreqStatusValues.DONE)}
                        />
                        <p>Beglichen</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-bill-1"
                            className="radio"
                            value="option2"
                            checked={status === defaultPayreqStatusValues.OPEN}
                            onChange={() => setstatus(defaultPayreqStatusValues.OPEN)}
                        />
                        <p>Ausstehend</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-bill-1"
                            className="radio"
                            value="option3"
                            checked={status === defaultPayreqStatusValues.OVERDUE}
                            onChange={() => setstatus(defaultPayreqStatusValues.OVERDUE)}
                        />
                        <p>Überfällig</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-bill-1"
                            className="radio"
                            value="option3"
                            checked={status === defaultPayreqStatusValues.CANCELED}
                            onChange={() => setstatus(defaultPayreqStatusValues.CANCELED)}
                        />
                        <p>Storniert</p>
                    </div>
                </div>
            )}
            {/* Filter Employee Position */}
            {props.changePosition && props.statusType === "employee" && (
                <div className="flex flex-col gap-1 pt-4">
                    <h3 className="py-1 font-medium">Bestimmte Position anzeigen</h3>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-position"
                            className="radio"
                            value="option1"
                            checked={position === undefined}
                            onChange={() => setposition(undefined)}
                        />
                        <p>Alle</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-employee-2"
                            className="radio"
                            value="option2"
                            checked={position === defaultPositions.MA}
                            onChange={() => setposition(defaultPositions.MA)}
                        />
                        <p>Mitarbeiter</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-employee-2"
                            className="radio"
                            value="option3"
                            checked={position === defaultPositions.BO3}
                            onChange={() => setposition(defaultPositions.BO3)}
                        />
                        <p>Backoffice 3</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-employee-2"
                            className="radio"
                            value="option4"
                            checked={position === defaultPositions.BO2}
                            onChange={() => setposition(defaultPositions.BO2)}
                        />
                        <p>Backoffice 2</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-employee-2"
                            className="radio"
                            value="option5"
                            checked={position === defaultPositions.BO1}
                            onChange={() => setposition(defaultPositions.BO1)}
                        />
                        <p>Backoffice 1</p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-employee-2"
                            className="radio"
                            value="option6"
                            checked={position === defaultPositions.ADMIN}
                            onChange={() => setposition(defaultPositions.ADMIN)}
                        />
                        <p>Admin</p>
                    </div>
                </div>
            )}
            {/* Filter Archived */}
            {props.changeArchived && (
                <div className="flex flex-col gap-1 pt-4">
                    <h3 className="py-1 font-medium">Archiv anzeigen</h3>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-offer-archive-1"
                            className="radio"
                            value="option1"
                            checked={archived === false}
                            onChange={() => setarchived(false)}
                        />
                        <p>
                            Archivierte{" "}
                            {props.statusType === "offer"
                                ? "Angebote"
                                : props.statusType === "bill"
                                ? "Rechnungen"
                                : props.statusType === "object"
                                ? "Objekte"
                                : props.statusType === "customer"
                                ? "Kunden"
                                : "Mitarbeiter"}{" "}
                            nicht anzeigen
                        </p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-offer-archive-1"
                            className="radio"
                            value="option2"
                            checked={archived === undefined}
                            onChange={() => setarchived(undefined)}
                        />
                        <p>
                            Archivierte{" "}
                            {props.statusType === "offer"
                                ? "Angebote"
                                : props.statusType === "bill"
                                ? "Rechnungen"
                                : props.statusType === "object"
                                ? "Objekte"
                                : props.statusType === "customer"
                                ? "Kunden"
                                : "Mitarbeiter"}{" "}
                            mit anzeigen
                        </p>
                    </div>
                    <div className="flex flex-row gap-6 pl-2">
                        <input
                            type="radio"
                            name="radio-group-offer-archive-1"
                            className="radio"
                            value="option3"
                            checked={archived === true}
                            onChange={() => setarchived(true)}
                        />
                        <p>
                            Nur Archivierte{" "}
                            {props.statusType === "offer"
                                ? "Angebote"
                                : props.statusType === "bill"
                                ? "Rechnungen"
                                : props.statusType === "object"
                                ? "Objekte"
                                : props.statusType === "customer"
                                ? "Kunden"
                                : "Mitarbeiter"}{" "}
                            anzeigen
                        </p>
                    </div>
                </div>
            )}
            {/* Filter Dates */}
            {props.changeDateSince && props.changeDateTo && (
                <div className="flex flex-col gap-1 pt-6">
                    <h3 className="py-1 font-medium">Nach Zeitraum filtern</h3>
                    <BaseInputField
                        value={props.searchdateSince !== "" ? props.searchdateSince : dateSince}
                        onChange={setdateSince}
                        label={"von"}
                        type={"date"}
                    />
                    <BaseInputField
                        value={props.searchdateTo !== "" ? props.searchdateTo : dateTo}
                        onChange={setdateTo}
                        label={"bis"}
                        type={"date"}
                    />
                </div>
            )}
            {/* Use & Reset */}
            <div className="flex flex-row items-center justify-between mt-6">
                <p
                    className="underline text-sm cursor-pointer"
                    onClick={() => {
                        props.removeFilter()
                        removeInternFilterValues()
                    }}>
                    Zurücksetzen
                </p>
                <button className="btn btn-primary btn-outline" onClick={useFilter}>
                    Anwenden
                </button>
            </div>
        </motion.div>
    )
}
