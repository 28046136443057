import { useContext, useEffect, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import OfferModalScreen1 from "./ModalOfferScreen1"
import OfferModalScreen2 from "./ModalOfferScreen2"
import OfferModalScreen3 from "./ModalOfferScreen3"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { getAllCustomerData, getSingleCustomerData } from "../../../Kunden/api/apiCalls"
import { useAddOfferMutation } from "../../api/services"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { OfferPost, PositionData } from "../../../../data/Types"
import { preventKeyDown } from "../../../../services/functions"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { messageError } from "../../../../services/messages"

export default function AddOfferModal() {
    const [screen2, setscreen2] = useState(false)
    const [screen3, setscreen3] = useState(false)
    const [customer, setcustomer] = useState("")
    const {
        data: dataCustomer,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: ["customerData"],
        queryFn: getAllCustomerData,
    })
    const {
        data: singleCustomer,
        error: errorSingleCustomer,
        isLoading: isLoadingSingleCustomer,
    } = useQuery({
        queryKey: ["singleCustomerData" + customer],
        queryFn: () => getSingleCustomerData(customer),
        enabled: !(customer === ""),
    })

    useEffect(() => {
        setobject(undefined)
    }, [customer])
    const [object, setobject] = useState<string>()
    const [mailText, setmailText] = useState(
        "Bitte beachten Sie mögliche Fristen in den beigefügten Dokumenten. Zögern Sie nicht, uns bei Fragen oder weiteren Anliegen zu kontaktieren."
    )
    const [clearInputDropDown, setclearInputDropDown] = useState(false)
    const [sendLV, setsendLV] = useState(false)
    const [documents, setdocuments] = useState<any[]>()
    const [positions, setposition] = useState<PositionData[] | []>([
        {
            position_id: "1",
            description: "",
            tax: 0,
            unit: "",
            amount: 1,
            singlePrice: 0,
        },
    ])

    const clearInput = () => {
        setscreen2(false)
        setscreen3(false)
        setcustomer("")
        setobject("")
        setdocuments([])
        setclearInputDropDown(true)
        setTimeout(() => setclearInputDropDown(false), 100)
        setposition([
            {
                position_id: "1",
                description: "",
                tax: 0,
                unit: "",
                amount: 1,
                singlePrice: 0,
            },
        ])
    }

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addOffer } = useAddOfferMutation(queryClient, modalCon, clearInput)
    const handleNewOffer = () => {
        if (object) {
            const NewOffer: OfferPost = {
                date: Date(),
                customer_id: customer,
                object_id: object,
                mailText: mailText,
                positions: positions,
                status: "unanswered",
            }
            const formData = new FormData()
            formData.append("offer", JSON.stringify(NewOffer))
            documents?.forEach((doc: any) => {
                if (doc) {
                    formData.append("additionalDocuments", doc)
                }
            })
            addOffer({ body: formData, leistungsVerzeichnis: sendLV })
        } else {
            messageError("noteError", "Fehlgeschlagen!", "Es ist kein Objekt zugeordnet. Bitte diesen Fehler dem Adiminstrator melden.")
        }
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <BaseModal
                title={"Neues Angebot erstellen"}
                modalId={1}
                bottomRow={
                    <div className="flex justify-between items-center">
                        <button
                            onKeyDown={() => preventKeyDown}
                            disabled={!screen2}
                            className="underline text-sm"
                            onClick={() => {
                                if (screen2 && !screen3) {
                                    setscreen2(false)
                                } else if (screen2 && screen3) {
                                    setscreen3(false)
                                }
                            }}>
                            zurück
                        </button>
                        {screen3 ? (
                            <button onKeyDown={() => preventKeyDown} className={`btn btn-success`} onClick={handleNewOffer}>
                                Abschicken
                            </button>
                        ) : screen2 ? (
                            <button
                                onKeyDown={() => preventKeyDown}
                                className="btn bg-blue-300 border-none hover:bg-blue-400"
                                disabled={
                                    positions.some((obj) => {
                                        return Object.values(obj).some((wert) => wert === "" || wert === 0)
                                    }) || positions.length === 0
                                }
                                onClick={() => {
                                    setscreen3(true)
                                }}>
                                Weiter
                            </button>
                        ) : (
                            <button
                                onKeyDown={() => preventKeyDown}
                                className="btn bg-blue-300 border-none hover:bg-blue-400"
                                disabled={customer === "" || object === "" || object === undefined}
                                onClick={() => {
                                    setscreen2(true)
                                }}>
                                Weiter
                            </button>
                        )}
                    </div>
                }>
                <div className="overflow-hidden relative h-full w-full">
                    {(isLoadingCustomer || isLoadingSingleCustomer) && <Loading />}
                    {(errorCustomer || errorSingleCustomer) && <ErrorPage />}
                    {!(errorCustomer || errorSingleCustomer) && dataCustomer && (
                        <>
                            <OfferModalScreen1
                                visible={true}
                                objectId={object}
                                objectArray={singleCustomer?.objects}
                                customerArray={dataCustomer.filter((item: { archived: boolean }) => !item.archived)}
                                customerId={customer}
                                setcustomer={setcustomer}
                                setobject={setobject}
                                clearInputDropDown={clearInputDropDown}
                            />
                            <OfferModalScreen2 visible={screen2} position={positions} setpositions={setposition} />
                            <OfferModalScreen3
                                visible={screen3}
                                documents={documents}
                                setdocuments={setdocuments}
                                sendLV={sendLV}
                                setsendLV={setsendLV}
                                mailText={mailText}
                                setmailText={setmailText}
                                createdLV={singleCustomer?.objects?.find((item: { _id: string }) => item._id === object)?.listOfServices}
                            />
                        </>
                    )}
                </div>
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
