import { useContext, useEffect, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import BillModalScreen1 from "./ModalBillScreen1"
import BillModalScreen2 from "./ModalBillScreen2"
import BillModalScreen3 from "./ModalBillScreen3"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { getAllCustomerData, getSingleCustomerData } from "../../../Kunden/api/apiCalls"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { BillPost, PositionData } from "../../../../data/Types"
import dayjs from "dayjs"
import { useAddBillMutation } from "../../api/services"
import { preventKeyDown } from "../../../../services/functions"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { messageError } from "../../../../services/messages"

export default function AddBillModal() {
    const [screen2, setscreen2] = useState(false)
    const [screen3, setscreen3] = useState(false)
    const [customer, setcustomer] = useState("")

    const {
        data: dataCustomer,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: ["customerData"],
        queryFn: getAllCustomerData,
    })
    const {
        data: singleCustomer,
        error: errorSingleCustomer,
        isLoading: isLoadingSingleCustomer,
    } = useQuery({
        queryKey: ["singleCustomerData" + customer],
        queryFn: () => getSingleCustomerData(customer),
        enabled: !(customer === ""),
    })

    console.log(dataCustomer)
    console.log(singleCustomer)
    const [billType, setbillType] = useState("Rechnung")
    const [billCategorie, setbillCategorie] = useState("Hausmeister")
    const [billDue, setbillDue] = useState<string>("28")
    const [billDay, setbillDay] = useState<string>("28")
    const [manualReminderEnabled, setmanualReminderEnabled] = useState<boolean>(false)
    const [reminderStartOffsetInDays, setreminderStartOffsetInDays] = useState<string>("")
    const [reminderIntervalInDays, setreminderIntervalInDays] = useState<string>("")
    const [maxReminders, setmaxReminders] = useState<string>("")
    const [deliveryDate, setdeliveryDate] = useState("")
    useEffect(() => {
        setobject(undefined)
    }, [customer])
    const [object, setobject] = useState<string>()
    const [clearInputDropDown, setclearInputDropDown] = useState(false)
    const [positions, setposition] = useState<PositionData[]>([
        {
            position_id: "1",
            description: "",
            tax: 0,
            unit: "",
            amount: 1,
            singlePrice: 0,
        },
    ])
    const [documents, setdocuments] = useState<
        {
            title: ""
            fileUpload: File
            uploadedByUser: true
        }[]
    >()

    // clear input after closing the modal
    const clearInput = () => {
        setscreen2(false)
        setscreen3(false)
        setbillType("Rechnung")
        setbillDue("")
        setbillDay("")
        setdeliveryDate(dayjs().format())
        setdocuments([])
        setcustomer("")
        setobject("")
        setdeliveryDate("")
        setreminderStartOffsetInDays("")
        setreminderIntervalInDays("")
        setmaxReminders("")
        setclearInputDropDown(true)
        setTimeout(() => setclearInputDropDown(false), 100)
        setposition([
            {
                position_id: "1",
                description: "",
                tax: 0,
                unit: "",
                amount: 1,
                singlePrice: 0,
            },
        ])
    }

    // all about creating the new bill
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addBill } = useAddBillMutation(queryClient)

    const handleNewBill = () => {
        if (object) {
            const NewBill: BillPost = {
                date: Date(),
                customer_id: customer,
                object_id: object,
                positions: positions,
                billType: billType,
                billCategorie: billCategorie,
                billDue: Number(billDue),
                billDay: Number(billDay),
                serviceDate: dayjs(deliveryDate).format("DD.MM.YYYY"),
            }

            if (manualReminderEnabled) {
                NewBill.manualReminderEnabled = manualReminderEnabled
                NewBill.reminderStartOffsetInDays = Number(reminderStartOffsetInDays)
                NewBill.reminderIntervalInDays = Number(reminderIntervalInDays)
                NewBill.maxReminders = Number(maxReminders)
            }
            const formData = new FormData()
            formData.append("bill", JSON.stringify(NewBill))
            documents?.forEach((doc: any) => {
                if (doc) {
                    formData.append("additionalDocuments", doc)
                }
            })
            addBill(formData)
            clearInput()
            modalCon?.trigger(0)
        } else {
            messageError("noteError", "Fehlgeschlagen!", "Es ist kein Objekt zugeordnet. Bitte diesen Fehler dem Adiminstrator melden.")
        }
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <BaseModal
                title={"Neue Rechnung erstellen"}
                modalId={1}
                bottomRow={
                    <div className="flex justify-between items-center">
                        <button
                            onKeyDown={() => preventKeyDown}
                            disabled={!screen2}
                            className="underline text-sm"
                            onClick={() => {
                                if (screen2 && !screen3) {
                                    setscreen2(false)
                                } else if (screen2 && screen3) {
                                    setscreen3(false)
                                }
                            }}>
                            zurück
                        </button>
                        {screen3 ? (
                            <button onKeyDown={() => preventKeyDown} className={`btn btn-success`} onClick={handleNewBill}>
                                Abschicken
                            </button>
                        ) : screen2 ? (
                            <button
                                onKeyDown={() => preventKeyDown}
                                className="btn bg-blue-300 border-none hover:bg-blue-400"
                                disabled={
                                    positions.some((obj) => {
                                        return Object.values(obj).some((wert) => wert === "" || wert === 0)
                                    }) || positions.length === 0
                                }
                                onClick={() => {
                                    setscreen3(true)
                                }}>
                                Weiter
                            </button>
                        ) : (
                            <button
                                onKeyDown={() => preventKeyDown}
                                className="btn bg-blue-300 border-none hover:bg-blue-400"
                                disabled={
                                    customer === "" ||
                                    object === "" ||
                                    deliveryDate === "" ||
                                    (manualReminderEnabled &&
                                        (reminderIntervalInDays === "" || reminderStartOffsetInDays === "" || maxReminders === ""))
                                }
                                onClick={() => {
                                    console.log(deliveryDate)
                                    setscreen2(true)
                                }}>
                                Weiter
                            </button>
                        )}
                    </div>
                }>
                <div className="overflow-hidden relative h-full w-full">
                    {(isLoadingCustomer || isLoadingSingleCustomer) && <Loading />}
                    {(errorCustomer || errorSingleCustomer) && <ErrorPage />}
                    {!(errorCustomer || errorSingleCustomer) && dataCustomer && (
                        <>
                            <BillModalScreen1
                                billCategorie={billCategorie}
                                setbillCategorie={setbillCategorie}
                                visible={true}
                                objectArray={singleCustomer?.objects}
                                customerArray={dataCustomer.filter((item: { archived: boolean }) => !item.archived)}
                                customerId={customer}
                                objectId={object}
                                setcustomer={setcustomer}
                                setobject={setobject}
                                billType={billType}
                                setbillType={setbillType}
                                setbillDue={setbillDue}
                                setbillDay={setbillDay}
                                deliveryDate={deliveryDate}
                                setdeliveryDate={setdeliveryDate}
                                clearInputDropDown={clearInputDropDown}
                                manualReminderEnabled={manualReminderEnabled}
                                reminderStartOffsetInDays={reminderStartOffsetInDays}
                                reminderIntervalInDays={reminderIntervalInDays}
                                maxReminders={maxReminders}
                                setmanualReminderEnabled={setmanualReminderEnabled}
                                setreminderStartOffsetInDays={setreminderStartOffsetInDays}
                                setreminderIntervalInDays={setreminderIntervalInDays}
                                setmaxReminders={setmaxReminders}
                            />
                            <BillModalScreen2 visible={screen2} position={positions} setpositions={setposition} />
                            <BillModalScreen3 visible={screen3} documents={documents} setdocuments={setdocuments} />
                        </>
                    )}
                </div>
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
