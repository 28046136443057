import { QueryClient, useMutation } from "@tanstack/react-query"
import { Customer } from "../types/Types"
import { deleteAdministrator, deleteDocFromCustomer, postNewDocToCustomer, updateCustomer } from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"
import { useState } from "react"

// delete administrator
export const useDeleteAdministratorMutation = (queryClient: QueryClient, setdeleteAdminScreen: (e: boolean) => void, setchoosenAdmin: () => void) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: deleteAdministrator,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Verwalter wird gerade gelöscht.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Der Verwalter wurde erfolgreich gelöscht.")
            setdeleteAdminScreen(false)
            setchoosenAdmin()
            queryClient.invalidateQueries({
                queryKey: ["administratorData"],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Verwalter konnte nicht gelöscht werden.")
            console.log(err)
        },
    })

    return mutation
}

// update customer data
export const useUpdateCustomerMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: Customer }>({
        mutationFn: updateCustomer,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Kunde wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Kunde wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: ["singleCustomerData" + variables.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Kunde konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// upload document(s) to customer
export const useAddDocToCustomerMutation = (queryClient: QueryClient) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToCustomer,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Dokument wird gerade gelöscht.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Das Dokument wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({
                queryKey: ["singleCustomerData" + variables.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Dokument konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })

    return mutation
}

// delete doc-data from customer & from s3
export const usePatchDocCustomerMutation = (queryClient: QueryClient, modalCon: any, modalConId: number) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; s3Key: string }>({
        mutationFn: deleteDocFromCustomer,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Dokument wird gerade gelöscht.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Das Dokument wurde erfolgreich gelöscht.")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleCustomerData" + variables.id],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Dokument konnte nicht gelöscht werden.")
            console.log(err)
        },
    })

    return mutation
}
