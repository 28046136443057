import { QueryClient, useMutation } from "@tanstack/react-query"
import { Employee } from "../types/Types"
import { deleteDocFromEmployee, postNewDocToEmployee, resetPWFromEmployee, updateEmployee, updateEmployeeKcGroup } from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"
import { useState } from "react"

// update employee data
export const useUpdateEmployeeMutation = (queryClient: QueryClient, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; body: Employee }>({
        mutationFn: updateEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung..." + notificationKey, "Der Mitarbeiter wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Mitarbeiter wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: ["employeeData"] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Mitarbeiter konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// update employee kc group
export const useUpdateEmployeeKcGroupMutation = (queryClient: QueryClient, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { kcid: string; gid: string }>({
        mutationFn: updateEmployeeKcGroup,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung..." + notificationKey, "Die Position des Mitarbeiters wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!" + notificationKey, "Der Mitarbeiter wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: ["employeeData"] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Mitarbeiter konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
    return mutation
}

// reset pw from user
export const usePWResetEmployeeMutation = (queryClient: QueryClient, modalCon: any, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: resetPWFromEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Passwort des Mitarbeits wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Passwort des Mitarbeiters wurde erfolgreich aktualisiert.")
            modalCon?.trigger(7)
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Passwort des Mitarbeiters konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// upload document(s) to employee
export const useAddDocToEmployeeMutation = (queryClient: QueryClient, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Dokument wird gerade hinzugefügt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Dokument wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Dokument konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })

    return mutation
}

// delete doc-data from employee & from s3
export const usePatchDocEmployeeMutation = (queryClient: QueryClient, modalCon: any, modalConId: number, id: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, { id: string; file: string }>({
        mutationFn: deleteDocFromEmployee,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Dokument wird gerade gelöscht.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Das Dokument wurde erfolgreich gelöscht.")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Dokument konnte nicht gelöscht werden.")
            console.log(err)
        },
    })

    return mutation
}
