import { QueryClient, useMutation } from "@tanstack/react-query"
import { archiveDataGeneral, deleteDataGeneral, getDocumentFromAnyType, postNewDataGeneral } from "./apiCalls"
import { ArchiveDataParams, DeleteDataParams, PostNewDataParams } from "./Types/Types"
import { messageError, messagePending, messageSuccess } from "../../services/messages"
import { useState } from "react"

// download document
export const useDownloadDocumentMutation = () => {
    
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<Blob, Error, { id: string; s3Key: string; filetype: string; filename: string }>({
        mutationFn: getDocumentFromAnyType,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Das Dokument wird gerade heruntergeladen.")
        },
        onSuccess: (data, variables) => {
            const url = window.URL.createObjectURL(data)
            const a = document.createElement("a")
            a.href = url
            a.download = variables.filename
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
            messageSuccess(notificationKey, "Fertig!", "Das Dokument wurde erfolgreich heruntergeladen.")
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Das Dokument konnte nicht heruntergeladen werden.")
            console.log(err)
        },
    })

    return mutation
}

// post function - add customer / object / employee
export const usePostDataMutation = (queryClient: QueryClient, modalCon: any, pageType: string, refreshData: string, clearInput: () => void) => {
    
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, PostNewDataParams>({
        mutationFn: postNewDataGeneral,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", pageType + " wird gerade erstellt.")
        },
        onSuccess: () => {
            clearInput()
            modalCon?.trigger(0)
            messageSuccess(notificationKey, "Fertig!", pageType + " wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", pageType + " konnte nicht erstellt werden.")
            console.log(err)
        },
    })

    return mutation
}

// delete function - delete customer / object / employee
export const useDeleteDataMutation = (queryClient: QueryClient, modalCon: any, pageType: string, refreshData: string) => {
    
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, DeleteDataParams>({
        mutationFn: deleteDataGeneral,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", pageType + " wird gerade gelöscht.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", pageType + " wurde erfolgreich gelöscht.")
            modalCon?.trigger(4)
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", pageType + " konnte nicht gelöscht werden.")
            console.log(err)
        },
    })

    return mutation
}

// archive function - update customer / object / employee
export const useArchiveDataMutation = (queryClient: QueryClient, modalConFunction: () => void, refreshData: string, pageType: string) => {
    
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, ArchiveDataParams>({
        mutationFn: archiveDataGeneral,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", pageType + " wird gerade archiviert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", pageType + " wurde erfolgreich archiviert.")
            modalConFunction()
            queryClient.invalidateQueries({ queryKey: [refreshData] })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", pageType + " konnte nicht archiviert werden.")
            console.log(err)
        },
    })

    return mutation
}
