import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import BillTableRow from "../components/bill/BillTableRow"
import { useContext, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import AddBillModal from "../components/addBillModal/AddBillModal"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { BillGet } from "../../../data/Types"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../auth/RoleMapping"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { ReactComponent as PLUSINCIRCLE } from "../../../assets/PlusInCircleIcon.svg"
import { ReactComponent as OFFER } from "../../../assets/OfferIcon.svg"
import { ReactComponent as PAYMENT } from "../../../assets/PaymentIcon.svg"
import { ReactComponent as ARROWUP } from "../../../assets/ArrowUpIcon.svg"
import { ReactComponent as ARROWDOWN } from "../../../assets/ArrowDownIcon.svg"

export default function BillPage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const [filterType, setfilterType] = useState("")
    const [filterCategorie, setfilterCategorie] = useState("")
    const [filterDateSince, setfilterDateSince] = useState("")
    const [filterDateTo, setfilterDateTo] = useState("")
    const [filterArchived, setfilterArchived] = useState<boolean | undefined>(false)

    // Get Data Query
    const { error, data, isLoading } = useQuery({
        queryKey: ["billData" + page + searchParams + filterType + filterDateSince + filterDateTo + filterArchived + filterCategorie],
        queryFn: () =>
            getPaginatedData({
                pageType: "bill",
                page: page,
                limit: 15,
                search: searchParams,
                startDate: filterDateSince,
                endDate: filterDateTo,
                archived: filterArchived,
                billType: filterType,
                billCategorie: filterCategorie,
            }),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const [sort, setsort] = useState(false)

    const [showFilter, setshowFilter] = useState(false)

    const handleRemoveFilter = () => {
        setfilterType("")
        setfilterCategorie("")
        setfilterDateTo("")
        setfilterDateSince("")
        setfilterArchived(false)
    }

    const displayedBills = useMemo(() => data?.docs, [data?.docs])

    return (
        <BasePage>
            <HeadBanner
                title={"Rechnungen"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
                        <PLUSINCIRCLE className="w-8 h-8 cursor-pointer hidden md:block" onClick={() => modalCon?.trigger(1)} />
                    </RenderWhenAuthorized>
                }
            />
            {/* UPPER ROW OF INPUT & BUTTON */}
            <section className="w-full flex flex-wrap justify-between gap-4 py-4 md:px-4 items-center relative max-h-[18vh]">
                <div className="flex gap-4 w-full sm:w-fit">
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => setsearchTerm(e.target.value)}
                        type="text"
                        value={searchTerm ?? null}
                        placeholder="Kunden / Objekt / Rechnungsnummer suchen..."
                        className="w-96 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                </div>
                <div className="flex flex-row gap-4">
                    <button className="btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => navigate("/buchhaltung/angebote")}>
                        <OFFER className="w-6 h-6" />
                        Angebote
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => navigate("/buchhaltung/zahlungen")}>
                        <PAYMENT className="w-6 h-6" />
                        Zahlungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setsort(!sort)
                        }}>
                        {sort ? <ARROWUP className="w-4 h-4" /> : <ARROWDOWN className="w-4 h-4" />}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                    <BaseFilterModal
                        visible={showFilter}
                        searchdateSince={filterDateSince}
                        searchdateTo={filterDateTo}
                        changeDateTo={setfilterDateTo}
                        changeDateSince={setfilterDateSince}
                        removeFilter={handleRemoveFilter}
                        searchbillType={filterType}
                        changebillType={setfilterType}
                        searchbillCategorie={filterCategorie}
                        changebillCategorie={setfilterCategorie}
                        setVisible={() => setshowFilter(!showFilter)}
                        searchArchived={filterArchived}
                        changeArchived={setfilterArchived}
                        statusType={"bill"}
                    />
                </div>
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !error && !isLoading && (
                <>
                    {/* TABLE */}
                    <section className="w-full md:px-4 h-[65vh] xl:max-h-[75vh] overflow-x-hidden">
                        <table className="table table-md table-pin-rows bg-white shadow-lg">
                            <thead>
                                <tr className=" h-12 bg-gray-200 border-none">
                                    <th className=" hidden lg:table-cell text-base min-w-[10rem] w-fit text-center">Erstellungsdatum</th>
                                    <th className=" text-base w-fit text-center truncate">{window.innerWidth > 640 ? "Rechnungsnummer" : "Nr."}</th>
                                    <th className="hidden lg:table-cell text-base min-w-[9rem] w-fit text-center">Typ</th>
                                    <th className=" text-base sm:min-w-[8rem] w-fit text-center">Betrag</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Kunde</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Objekt</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll">
                                {displayedBills?.map((item: BillGet) => (
                                    <BillTableRow
                                        key={item._id}
                                        id={item._id}
                                        title={item.nr}
                                        customer={item?.customer_id?.customer?.name}
                                        date={item.date}
                                        delivery={item.serviceDate}
                                        object={item?.object_id}
                                        amount={item.positions}
                                        type={item.billType}
                                        archived={item.archived}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {(!displayedBills || displayedBills.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erstellten Rechnungen</p>
                        )}
                    </section>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            pageSize={15}
                            defaultCurrent={1}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <AddBillModal />
        </BasePage>
    )
}
