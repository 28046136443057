import { useMutation } from "@tanstack/react-query"
import { resendMail } from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../messages"
import { useState } from "react"

export const useResendMailMutation = () => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: resendMail,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Mail wird gerade versandt.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Die Mail wurde erfolgreich versandt.")
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Die Mail konnte nicht neu versandt werden.")
            console.log(err)
        },
    })

    return mutation
}
