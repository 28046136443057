import { useEffect, useState } from "react"
import { PositionData, BillGet } from "../data/Types"

// prevent the tabulator key down event
export const preventKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLSelectElement>
) => {
    if (event.key === "Tab") {
        event.preventDefault()
    }
}

// compare values - return true if a value is different to its comparison
export const compareValues = (
    values: {
        v1: string | number | boolean | undefined
        v2: string | number | boolean | undefined
    }[]
) => {
    const bool = values.map((item) => {
        if (item.v1 !== item.v2) {
            return false
        } else {
            return true
        }
    })
    const result = bool.includes(false)
    return result
}

// extract suffix from file name
export function getSuffix(fileName: string) {
    const dotIndex = fileName.lastIndexOf(".")

    if (dotIndex === -1 || dotIndex === fileName.length - 1) {
        return ""
    }

    return fileName.substring(dotIndex + 1)
}

// check if value is empty - returns TRUE if there are empty values given
export const checkForEmptyValues = (values: (string | number | Number)[]) => {
    const bool: any = values.map((item) => {
        if (item === "" || item === undefined || item === 0) {
            return true
        } else {
            return false
        }
    })

    const result = bool.includes(true)
    return result
}

// calculates total amount from bill
export const calculateTotal = (bill: BillGet) => {
    return bill?.positions
        ?.map((position) => position.amount * position.singlePrice * (1 + 0.01 * position.tax))
        .reduce((acc, value) => acc + value, 0)
        .toFixed(2)
        .replace(".", ",")
}

// calculates total amount from positions
export const calculatePositionTotal = (positions: PositionData[]) => {
    return positions
        ?.map((position) => position.amount * position.singlePrice * (1 + 0.01 * position.tax))
        .reduce((acc, value) => acc + value, 0)
        .toFixed(2)
}

// validate email input
export const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export const returnHumanizedMailtype = (mailType: string) => {
    switch (mailType) {
        case "bill":
            return "Rechnung"
        case "offer":
            return "Angebot"
        case "reminder":
            return "Mahnung"
        case "cancel_bill":
            return "Stornierung"
        default:
            return
    }
}

export const useDelayWhenSearchWithPagination = (value: string) => {
    const [debouncedValue, setdebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setdebouncedValue(value)
        }, 1000)

        return () => {
            clearTimeout(handler)
        }
    }, [value])

    return debouncedValue ?? ""
}

export function formatCurrency(amount: number, currency = "EUR", locale = "de-DE") {
    if (isNaN(amount)) return amount
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    }).format(amount)
}
