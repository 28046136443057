import { useState } from "react"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { setReminder, updateTransactionAndBillData, updateTransactionAndBillPullData } from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"

export const useUpdateTransactionMutation = (queryClient: QueryClient, modalCon: any, modalClose?: boolean) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillData,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Transaktionen werden gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Transaktionen wurden erfolgreich aktualisiert.")

            modalCon?.trigger(1)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["transactionData"],
            })
        },

        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Transaktionen konnten nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

export const useUpdateTransactionPullMutation = (queryClient: QueryClient, modalCon: any) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillPullData,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Status der Zahlung wird gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Der Zahlungsstatus wurde erfolgreich aktualisiert.")
            modalCon?.trigger(1)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["transactionData"],
            })
        },

        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Zahlungsstatus konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

export const useSetReminderMutation = (queryClient: QueryClient, modalCon: any, modalId: number, billId: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, String>({
        mutationFn: setReminder,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Status der Mahnung wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Status der Mahnung wurde erfolgreich aktualisiert.")

            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["PaymentRequirements" + billId],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Status der Mahnung konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}
