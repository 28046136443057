import { motion } from "framer-motion"
import { NewOfferScreen1 } from "../../types"
import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
export default function OfferModalScreen1({ visible, customerArray, objectArray, customerId, setcustomer, setobject, objectId }: NewOfferScreen1) {
    return (
        <motion.div
            initial={{
                x: 0,
            }}
            animate={{
                x: visible ? 0 : "-100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full overflow-hidden pl-2">
            <BaseInputSelection classProps="pt-4" data={customerArray} label={"Kunde: *"} placeholder={"Kunde auswählen"} setId={setcustomer} />
            <BaseInputSelection classProps="pt-2" data={objectArray ?? []} label={"Objekt: *"} value={objectId} placeholder={"Objekt auswählen"} setId={setobject} />
            {customerId !== "" && objectArray?.length === 0 && <p className="text-xs text-gray-300">Diesem Kunde sind keine Objekte zugewiesen.</p>}
        </motion.div>
    )
}
